// notes-singles layout.
//
// .notes						- Main notes styles.
//
// Styleguide notes layout parts.


.l-notes-single {
	.m-header__nav__nav ul li.menu-item-13 a {
		color: $color-base;
		&::after {
			height: 10px;
			background-color: $color-base;
		}
	}

	.l-page__container {
		p,
		ul,
		ol {
			@include margin(0 0 $base-spacing 0);
		}
		.snippetcpt-wrap {
			background-color: $color-third;
			@include margin(0 0 $base-spacing 0);
			padding: $base-spacing / 2;
		}
		.snippet-buttons {
			display: none !important;
		}
		.ace_editor {
			background-color: transparent !important;
			font-size: 14px !important;
		}
		.ace-kuroir .ace_support.ace_function {
			color: $color-primary !important;
		}
		pre {
			margin: 0;
		}
		code {
			font-weight: bold;
			color: $color-base;
			
		}
		h3 {
			color: $color-primary;
			@include margin(0 0 $base-spacing 0);
		}
		h4 {
			color: $color-base;
			@include rem-fontsize($h6-font-size);
			font-family: $font-stack-common;
			font-weight: 900;
			text-transform: initial;
			@include margin(($base-spacing * 2) 0 $base-spacing 0);
		}
		h5 {
			color: $color-base;
			@include rem-fontsize($font-size-base);
			font-family: $font-stack-common;
			font-weight: 900;
			text-transform: initial;
			@include margin($base-spacing  0 $base-spacing 0);
		}
		hr.thin {
			background-color: $color-primary;
		}
		a {
			color: $color-primary;
			border-bottom: 1px solid $color-primary;
			text-decoration: none;
			&:hover {
				color: $color-primary-dark;
				border-bottom: 1px solid $color-primary-dark;
				text-decoration: none;
			}
		}
	}
	
	.l-notes-single__thumbnail {
		display: none;
		float: left;
		max-width: 70%;
		@include padding(0 $base-spacing*2 $base-spacing 0);
		margin-left: -39.5%;
		margin-bottom: 0;
		@include media($small-up) {
			display: inline-block;
		}
		@include media($large-up) {
			max-width: 640px;
		}
	}
	
	.l-notes-single__time {
		@include margin(0);
		time {
			@include padding($base-spacing/4 $base-spacing/2);
			background-color: $color-primary;
			color: $color-white;
			@include rem-fontsize($font-size-small);
			font-family: $font-stack-common;
			font-weight: 700;
		}
	}
	
	.l-notes-single__translation {
		@include padding($base-spacing);
		background-color: $color-primary;
		color: $color-white;
		font-family: $font-stack-common;
		a {
			color: $color-white;
			border-bottom: 1px solid $color-white;
			text-decoration: none;
			&:hover {
				color: $color-white;
				border-bottom: 1px solid $color-white;
				text-decoration: none;
			}
		}
	}
}
