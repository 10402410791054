// Header.
//
// .m-header							- Module header styles.
//
// Styleguide for header module.


.m-header {
	background-color: $color-white;
	position: relative;
	&__hello {
		background-color: $color-third;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: bottom center;
		background-attachment: fixed;
		z-index: 10;
		@include padding($base-spacing*3 $base-spacing);
		@include media($small-up) {
			@include padding($base-spacing*4 $base-spacing*2 $base-spacing*4 $base-spacing*2);
		}
		section {
			@include outer-container;
			@include row(table);
			@include clearfix;
		}
		&__content {
			position: relative;
			&--left {
				display: none;
				@include padding(0 $base-spacing*4 0 $base-spacing*4);
				vertical-align: top;
				text-align: center;
				@include media($small-up) {
					@include span-columns(6, table);
				}
				a {
					display: block;
					position: relative;
					text-decoration: none;
				}
				svg {
					height: auto;
					width: 70%;
					#logo-top {
						path, polygon {
							fill: $color-secondary;
						}
					}
					#logo-middle {
						path, polygon {
							fill: $color-base;
						}
					}
					#logo-bottom {
						path, polygon {
							fill: $color-base;
						}
						.stroke {
							fill: none;
							stroke-linecap: square;
							stroke-miterlimit: 10;
							stroke-width: 1.5px;
							stroke: $color-base;
						}
					}
				}
			}
			&--right {
				@include fill-parent;
				@include padding(0);
				vertical-align: middle;
				@include media($small-up) {
					@include span-columns(6, table);
					@include padding(0 0 0 $base-spacing*2);
				}
			}
		}
		h2 {
			font-weight: $font-weight-common;
			@include rem-fontsize(16);
		}
		h1 {
			@include headings(h3);
		}
		p {
			@include headings(h1);
			color: $color-white;
		}
	}

	&__nav {
		display: block;
		position: relative;
		background-color: $color-white;
		height: auto;
		transition: transform .6s ease-out, box-shadow .6s ease-out;
		@include prefixer(box-shadow, 0px -25px 30px 0px rgba(0, 0, 0, 0.10), webkit moz spec);
		@include padding($base-spacing/2 0 0 0);
		@include media($medium-up) {
			@include padding($base-spacing/2 $base-spacing*2 0 $base-spacing*2);
		}
		section {
			@include outer-container;
			@include row(table);
			border-bottom: 1px solid $color-third;
			@include transition(border .9s);
		}
		&__logo {
			@include fill-parent;
			@include row;
			@include media($medium-up) {
				@include span-columns(6, table);
			}
			background-image: url('../../images/logo-hellodeloo.svg');
			background-size: auto 45px;
			background-repeat: no-repeat;
			background-position: $base-spacing $base-spacing/2;
			border-bottom: none;
			@include media($small-up) {
				background-position: $base-spacing*2 $base-spacing/2;
			}
			@include media($medium-up) {
				background-position: left $base-spacing/2;
			}
			span {
				display: inline-block;
				@include rem-fontsize(40);
				line-height: 1;
				color: $color-base;
				@include transition(padding .3s ease-out .9s, background-color .3s ease-out .6s);
				background-color: transparent;
				@include padding($base-spacing/1.5 0 $base-spacing/1.5 $base-spacing*3.5);
				@include media($small-up) {
					@include padding($base-spacing/1.5 0 $base-spacing/1.5  $base-spacing*4.5);
				}
				@include media($medium-up) {
					background-color: $color-white;
					@include padding($base-spacing/1.5 0 $base-spacing/1.5 0);
				}
			}
		}
		&__nav {
			@include fill-parent;
			@include row;
			@include media($medium-up) {
				@include span-columns(6, table);

			}
			vertical-align: bottom;
			ul {
				@include unstyled;
				@include margin(0);
				display: none;
				height: auto;
				@include media($medium-up) {
					display: block;
					height: 70px;
					overflow: hidden;
					@include margin(0 0 0 $base-spacing*2);
				}
				li {
					display: block;
					@include margin(0);
					border-top: 1px solid $color-third;
					@include margin(0 $base-spacing/2 0 0);
					@include media($medium-up) {
						display: inline-block;
						@include margin(0 $base-spacing/2 0 0);
						border-top: none;
					}
					@include media($large-up) {
						@include margin(0 $base-spacing 0 0);
					}
					@include media($xlarge-up) {
						@include margin(0 $base-spacing*1.5 0 0);
					}
					&.current-menu-item a {
						color: $color-base;
						&::after {
							height: 10px;
							background-color: $color-base;
						}
					}

					&:first-child {
						display: none;
						@include media($large-up) {
							display: inline-block;
						}
					}

					&:last-child {
						@include margin(0);
						float: none;
						padding-top: 0;
						@include media($medium-up) {
							float: right;
							padding-top: 5px;
						}
						a {
							color: $color-base;
							background-color: $color-secondary;
							font-family: $font-stack-headings;
							font-weight: 700;
							text-transform: inherit;
							height: auto;
							@include rem-fontsize(18);
							@include padding($base-spacing/2 $base-spacing);

							@include media($medium-up) {
								@include padding($base-spacing/1.5);
							}

							&::after {
								display: none;
							}
							&:hover, &:focus, &:active {
								background-color: $color-base;
								color: $color-secondary;
							}
						}
					}
					&.menu-contactonly {
						@include media($medium-up) { float: right; }
						a {
							margin: 15px 0;
						}
					}
				}
				a {
					display: block;
					position: relative;
					border-bottom: none;
					line-height: 1;
					@include padding($base-spacing/2 $base-spacing);
					height: auto;
					@include monospace-text($color-third-dark);
					@include transition(color .3s ease-out, background-color .3s ease-out);
					@include media($small-up) {
						@include padding($base-spacing/1.5 $base-spacing*2);
					}
					@include media($medium-up) {
						display: inline-block;
						@include padding($base-spacing 0 0 0);
						height: 70px;
					}
					&::after {
						content: '';
						display: none;
						position: absolute;
						width: 100%;
						height: 0;
						bottom: 0;
						left: 0;
						z-index: 10;
						background-color: $color-third-dark;
						@include transition(height .1s ease-out);
						@include media($medium-up) {
							display: block;
						}
					}
					&:hover, &:focus, &:active {
						text-decoration: none;
						&::after {
							height: 10px;
							background-color: $color-third-dark;
							@include transition(height .3s ease-out);
						}
					}
				}
			}
		}
		&__burger {
			position: absolute;
			display: block;
			top: 0;
			right: 0;
			z-index: 1;
			cursor: pointer;
			background-position: $base-spacing $base-spacing/2;
			@include padding($base-spacing $base-spacing);
			@include media($small-up) {
				@include padding($base-spacing $base-spacing*2);
			}
			@include media($medium-up) {
				display: none;
			}
			i {
				display: block;
				background-image: url('../../images/icon-burger.svg');
				background-repeat: no-repeat;
				background-size: cover;
				height: 32px;
				width: 32px;
			}
		}
		&.is-fixed {
			position: fixed;
			width: 100%;
			top: 0;
			z-index: 100;
			@include prefixer(box-shadow, 0px 5px 15px 0px rgba(0, 0, 0, 0.10), webkit moz spec);
			@include transform(translate(0, -70px));
			&:hover, &:focus, &:active, &.is-scrollup {
				@include transform(translate(0, 0px));
				transition: transform .2s ease-out, box-shadow .6s ease-out;
			}
			section {
				border-bottom: none;
			}
			.m-header__nav__logo span {
				background-color: transparent;
				@include padding($base-spacing/1.5 0 $base-spacing/1.5 $base-spacing*3.5);
				@include media($small-up) {
					@include padding($base-spacing/1.5 0 $base-spacing/1.5  $base-spacing*4.5);
				}
				@include media($medium-up) {
					@include padding($base-spacing/1.5 0 $base-spacing/1.5 $base-spacing*2.7);
				}
			}
		}
		&.is-mobile {
			.m-header__nav__nav ul {
				display: block;
			}
			.m-header__nav__burger i {
				background-image: url('../../images/icon-burger-close.svg');
			}
		}
	}

}


// IE10+ Hack
_:-ms-input-placeholder, :root .m-header__nav__logo {
	background-image: url('../../images/android-chrome-192x192.png');
}