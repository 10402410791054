// All forms and forms elements styles.
//
// 1.				- Thanks to HTML5boilerplate (Source: github.com/nathansmith/formalize & www.sitepen.com).
// 2.          - Removes 'x' on right of search input when text is entered.
//
// Styleguide forms.

// 1.
form,
fieldset {
	border: none;
	margin: 0;
	padding: 0;
}
input,
button, select,
label {
	font-family: inherit;
	font-size: inherit;
}
label {
	display: inline-block;
	cursor: pointer;
}
legend {
	border: 0;
	white-space: normal;
}
button,
input,
select {
	font-family: $font-stack-common;
	font-size: inherit;
	margin: 0;
	vertical-align: middle;
}
textarea {
	min-height: 5em;
	font-size: inherit;
	font-family: inherit;
	vertical-align: top;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	cursor: pointer;
}
input {
	-webkit-appearance: none;
	border-radius: 0;
}

// 2.
input[type="search"] {
	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
		display: none;
	}
}

input, textarea {
  @include placeholder {
    color: rgba($color-base, 0.5);
  }
}