// realisations.
//
// .m-realisations							- Module realisations styles.
//
// Styleguide for realisations module.


.m-realisations {
	background-color: $color-third-light;
	@include padding($base-spacing*3 $base-spacing);
	@include media($small-up) {
		@include padding($base-spacing*4 $base-spacing*2 $base-spacing*4 $base-spacing*2);
	}
	section {
		@include outer-container;
		@include row(table);
	}
	h3 {
		@include headings(h2);
		color: $color-secondary;
	}
	h4 {
		@include monospace-text($color-third-dark);
	}
	hr.thin, hr.heavy, hr.heavyFull  {
		background-color: $color-third;
	}
	&__content {
		@include span-columns(6, table);
		&--full {
			position: relative;
			@include fill-parent;
			@include row;
			text-align: center;
		}
		&--left {
			vertical-align: middle;
			position: relative;
			display: none;
			@include media($small-up) {
				@include span-columns(6, table);
			}
		}
		&--right {
			vertical-align: top;
			@include fill-parent;
			@include padding(0);
			@include row;
			@include media($small-up) {
				@include span-columns(6, table);
				@include padding(0 0 0 $base-spacing*2);
			}
		}
		&__items {
			ul {
				@include unstyled;
			}
			li {
				@include monospace-text($color-white);
				@include rem-fontsize($font-size-smaller);
				border-top: 1px solid $color-third;
				@include transition(border-top .3s ease-out);
				&:hover, &:focus, &:active, &.is-active {
					border-top: 1px solid $color-third-light;
					+ li {
						border-top: 1px solid $color-third-light;
					}
				}
				&:first-child {
					border-top: none;
				}
				a {
					display: block;
					min-height: 60px;
					@include padding($base-spacing $base-spacing/2 0 $base-spacing*1.5);
					background-image: url('../../images/icon-arrow-right.svg');
					background-size: 16px 16px;
					background-repeat: no-repeat;
					background-position: left center;
					border-bottom: none;
					@include transition(all .3s ease-out);
					span {
						display: block;
						&:last-child {
							opacity: 0;
							@include transition(opacity .3s ease-out);
							@include monospace-text($color-third-dark);
							@include rem-fontsize(10);
						}
					}
					&:hover, &:focus, &:active {
						text-decoration: none;
						background-color: $color-white;
						background-position: $base-spacing/1.5 center;
						@include padding($base-spacing/1.5 $base-spacing/2 0 $base-spacing*2);
						background-image: url('../../images/icon-arrow-right__yellow.svg');
						span {
							&:first-child {
								color: $color-secondary;
							}
							&:last-child {
								opacity: 1;
							}
						}
					}
				}
				&.is-active {
					border-top: 1px solid $color-third-light;
					a {
						text-decoration: none;
						background-color: $color-secondary;
						background-position: $base-spacing/1.5 center;
						@include padding($base-spacing/1.5 $base-spacing/2 0 $base-spacing*2);
						background-image: url('../../images/icon-arrow-right.svg');
						span {
							&:first-child {
								color: $color-base;
							}
							&:last-child {
								opacity: 1;
								color: $color-white;
							}
						}
					}
				}
			}
		}
		&__more {
			text-align: center;
			@include media($small-up) {
				text-align: left;
			}
		}
		&__figure {
			display: block;
			position: relative;
			margin: 0;
			margin-bottom: $base-spacing;
			a {
				display: block;
				background-color: $color-secondary;
				img {
					opacity: 0.15;
					filter: grayscale(100%);
					@include transition(all .9s ease-out, opacity .6s, filter .3s);
				}
				&:hover {
					img {
						filter: grayscale(33%);
						opacity: 1;
					}
				}
				&:after {
					display: block;
					position: absolute;
					z-index: 1;
					content: '';
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-image: url('../../images/realisations-device__gris.png');
					background-repeat: no-repeat;
					background-size: cover;
					border-bottom: 1px solid $color-third-light;
				}
			}
		}
		&__focus {
			line-height: 1.6;
			&__projet {
				@include headings(h5);
			}
			&__desc {
				color: $color-third-darkest;
				font-weight: normal;
			}
			&__infos {
				text-decoration: none !important;
				@include monospace-text($color-third-dark);
				@include rem-fontsize(10);
				a {
					display: inline-block;
					color: $color-third-dark;
					&:hover, &:focus, &:active {
						color: $color-base;
					}
				}
			}
			&__tags {
				text-decoration: none !important;
				@include monospace-text($color-third);
				@include rem-fontsize(10);
				a {
					display: inline-block;
					color: $color-third-dark;
					&:hover, &:focus, &:active {
						color: $color-base;
					}
				}
			}
			&__voir {
				display: inline-block;
				text-decoration: none !important;
				padding-left: $base-spacing*1.2;
				background-image: url('../../images/icon-globe.svg');
				background-repeat: no-repeat;
				background-position: left center;
				background-size: 16px;
				color: $color-primary;
				@include rem-fontsize(14);
				font-weight: 700;
				&:hover, &:focus, &:active {
					color: $color-primary;
				}
			}
		}
	}
}