// Services layout.
//
// .services							- Main services styles.
//
// Styleguide services layout parts.


.l-services {
	//
	.m-header__hello {
		background-image: url('../../images/services-hello-bg.jpg');
		background-position: top left;
	}
	.l-page__container {
			background-image: url('../../images/services-bg.svg');
			background-position: bottom center;
			background-repeat: repeat-x;
		h3 {
			@include headings(h3);
			line-height: 1.3;
			color: $color-secondary;
		}
		section {
			position: relative;
		}
	}
	.l-page__container__content--left {
		position: relative;
		display: none;
		@include media($small-up) {
			@include span-columns(6, table);
		}
	}
	.m-services__icons__pinning {
		padding-top: $base-spacing*3;
	}

}