// Presttion layout.
//
// .realisation							- Main projets styles.
//
// Styleguide realisation layout parts.


.l-realisations {
  //
  .m-header__hello {
    background-image: url('../../images/realisations-hello-bg.jpg');
    background-position: top center;
  }
  .l-page__container__content--left {
    position: relative;
    vertical-align: top;
    display: none;
    @include media($small-up) {
      @include span-columns(6, table);
    }
    .m-realisations__content__pinning {
      padding-top: $base-spacing*5;
    }
    .m-realisations__content__figure {
      a {
        &:after {
          background-image: url('../../images/realisations-device__blanc.png');
          border-bottom: 1px solid $color-white;
        }
      }
    }
    .m-realisations__content__focus {
      min-height: 200px;
    }
  }
  .l-page__container__content--right {
    .m-realisations__content__items {
      li {
        @include monospace-text($color-white);
        @include rem-fontsize($font-size-smaller);
        border-top: 1px solid $color-third;
        @include transition(border-top .3s ease-out);
        &:hover, &:focus, &:active, &.is-active {
          border-top: 1px solid $color-white;
          + li {
            border-top: 1px solid $color-white;
          }
        }
        &:first-child {
          border-top: none;
        }
        a {
          display: block;
          min-height: 60px;
          @include padding($base-spacing $base-spacing/2 0 $base-spacing*1.5);
          background-image: url('../../images/icon-arrow-right.svg');
          background-size: 16px 16px;
          background-repeat: no-repeat;
          background-position: left center;
          border-bottom: none;
          @include transition(all .3s ease-out);
          &:hover, &:focus, &:active {
            color: $color-white;
            text-decoration: none;
            background-color: $color-base;
            background-position: $base-spacing/1.5 center;
            @include padding($base-spacing $base-spacing/2 0 $base-spacing*2);
            background-image: url('../../images/icon-arrow-right__white.svg');
          }
        }
        &.is-active {
          border-top: 1px solid $color-white;
          a {
            text-decoration: none;
            background-color: $color-secondary;
            background-position: $base-spacing/1.5 center;
            @include padding($base-spacing $base-spacing/2 0 $base-spacing*2);
            background-image: url('../../images/icon-arrow-right.svg');
          }
        }
      }
    }
  }

}