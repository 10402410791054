// Page layout.
//
// .page							- Main page styles.
//
// Styleguide page layout parts.


.l-page {
	//
	.m-header__hello {
		background-image: url('../../images/pages-hello-bg.jpg');
		background-position: top center;
	}
	&__container {
		@include padding($base-spacing*3 $base-spacing);
		@include media($small-up) {
			@include padding($base-spacing*4 $base-spacing*2 $base-spacing*5 $base-spacing*2);
		}
		section {
			@include outer-container;
			@include row(table);
		}
		h3 {
			@include headings(h2);
			line-height: 1.3;
			color: $color-secondary;
		}
		h4 {
			@include monospace-text($color-third);
		}
		hr.thin, hr.heavy, hr.heavyFull {
			background-color: $color-third;
		}
		blockquote {
			position: relative;
			@include padding($base-spacing $base-spacing 0 $base-spacing);
			@include rem-fontsize($font-size-base);
			color: $color-third-darker;
			font-style: italic;
			margin: 0;
			@include media($medium-up) {
				@include padding($base-spacing $base-spacing * 3 0 $base-spacing * 3);
			}
			&::before {
				content: '“';
				position: absolute;
				z-index: 1;
				top: 0;
				left: 0;
				@include rem-fontsize(50);
				color: $color-secondary;
				@include media($medium-up) {
					left: 0;
					@include rem-fontsize(60);
				}
			}
		}
		&__content {
			&--full {
				@include fill-parent;
				@include row;
				position: relative;
				section & { @include row; }
			}
			&--quarter {
				@include fill-parent;
				@include padding(0);
				position: relative;
				vertical-align: top;
				section & { @include row; }
				@include media($small-up) {
					@include span-columns(9, table);
					@include shift(3);
					@include padding(0 0 0 $base-spacing*2);
				}
			}
			&--large {
				@include fill-parent;
				@include padding(0);
				position: relative;
				vertical-align: top;
				section & { @include row; }
				@include media($small-up) {
					@include span-columns(10, table);
					@include shift(1);
					@include padding(0 0 0 $base-spacing*2);
				}
			}
			&--left {
				vertical-align: top;
				@include fill-parent;
				@include padding(0);
				@include row;
				@include media($small-up) {
					@include span-columns(6, table);
					@include padding(0 $base-spacing*2 0 0);
				}
			}
			&--right {
				vertical-align: top;
				@include fill-parent;
				@include padding(0);
				@include row;
				@include media($small-up) {
					@include span-columns(6, table);
					@include padding(0 0 0 $base-spacing*2);
				}
			}
			&--third {
				@include fill-parent;
				@include padding(0);
				@include row;
				@include media($medium-up) {
					@include span-columns(4, table);
					@include padding(0 $base-spacing*2);
				}
				vertical-align: top;
				&:nth-child(1) {
					@include padding(0 0 $base-spacing 0);
					border: none;
					@include media($medium-up) {
						@include padding(0 $base-spacing*2 0 0);
						border-right: 1px solid  $color-third;
					}
				}
				&:nth-child(3) {
					@include padding($base-spacing 0 0 0);
					border: none;
					@include media($medium-up) {
						@include padding(0 0 0 $base-spacing*2);
						border-left: 1px solid  $color-third;
					}
				}
			}
		}
		&__columns {
			@include fill-parent;
			@include row;
			@include media($medium-up) {
				@include columns(2);
				@include column-gap($base-spacing*4);
				@include column-rule(1px solid $color-third);
			}
			a { text-decoration: underline; }
		}
		&__more {
			text-align: center;
			@include padding($base-spacing*4 0 0 0);
		}
	}
}
