// Homepage layout.
//
// .homepage							- Main homepage styles.
//
// Styleguide homepage layout parts.


.l-hp {
	//
	.m-header__hello {
		background-image: url('../../images/homepage-hello-bg.jpg');
		@include media($small-up) {
			height: calc(100vh - 80px);
			padding-top: 50vh;
		}
		section {
			transform: translate(0, 0);
			@include media($small-up) {
				transform: translate(0, -50%);
			}
		}
	}
	.m-header__hello__content--left {
		> * {
			opacity: 0;
			transform: translate(0, 60px);
			transition: opacity 1.2s ease-out;
			&.is-visible {
				opacity: 1;
				transform: translate(0, 0);
			}
		}
		svg {
			width: 90%;
		}

	}

	.m-header__hello__content--right {
		> * {
			opacity: 0;
			transform: translate(0, 30px);
			transition: opacity 0.3s, transform 0.6s ease-out;
			&.is-visible {
				opacity: 1;
				transform: translate(0, 0);
			}
		}
	}

	hr.arrowBottom {
		animation: arrowbounce 3s infinite;
	}
}




@keyframes arrowbounce {
	80%, 86%, 92% {
		transform: translate(0, 0);
	}
	83% {
		transform: translate(0, 3px);
	}
	89% {
		transform: translate(0, 5px);
	}
}