// Buttons.
//
// .buttons							- Module buttons styles.
//
// Styleguide for buttons module.


%button {
	font-family: inherit;
	font-size: inherit;
	display: inline-block;
	position: relative;
	width: 200px;
	border: none;
	overflow: hidden;
	text-align: center;
	line-height: $line-height;
	@include padding($base-spacing/2 $base-spacing*2 $base-spacing/2 0);
	@include rem-fontsize($font-size-smaller);
	@include monospace-text($color-white);
	color: $color-base;
	border: 1px solid $color-base;
	@include transition(background-color .3s ease-out);
	&:hover, &:focus {
		color: $color-base;
		text-decoration: none;
		background-color: $color-white;
	}
	&::before {
		content: '+';
		color: $color-base;
		z-index: 10;
		position: absolute;
		top: 13px;
		right: 10px;
		width: 16px;
		height: 16px;
		line-height: 1;
		@include transition(right .3s ease-out, color .3s ease-out);
	}
	&::after {
		content: '';
		position: absolute;
		width: 49%;
		height: 200%;
		z-index: 1;
		right: -30%;
		top: -30%;
		border: 1px solid $color-base;
		@include transform(rotate(25deg));
		@include transition(width .3s ease-out, background-color .3s ease-out);
	}
	&:hover::before {
		right: 14px;
		color: $color-white;
	}
	&:hover::after {
		width: 52%;
		background-color: $color-base;
	}
}


.button {
	@extend %button;
	&--primary {
		color: $color-primary;
		border: 1px solid $color-primary;
		&:hover {
			color: $color-primary;
		}
		&::before {
			color: $color-primary;
		}
		&::after {
			border: 1px solid $color-primary;
		}
		&:hover::before {
			color: $color-white;
		}
		&:hover::after {
			background-color: $color-primary;
		}
	}
	&--primary-dark {
		color: $color-primary-dark;
		border: 1px solid $color-primary-dark;
		&:hover {
			color: $color-primary-dark;
		}
		&::before {
			color: $color-primary-dark;
		}
		&::after {
			border: 1px solid $color-primary-dark;
		}
		&:hover::before {
			color: $color-white;
		}
		&:hover::after {
			background-color: $color-primary-dark;
		}
	}
	&--secondary {
		color: $color-secondary;
		border: 1px solid $color-secondary;
		&:hover {
			color: $color-secondary;
		}
		&::before {
			color: $color-secondary;
		}
		&::after {
			border: 1px solid $color-secondary;
		}
		&:hover::before {
			color: $color-white;
		}
		&:hover::after {
			background-color: $color-secondary;
		}
	}
	&--secondary-dark {
		color: $color-secondary-dark;
		border: 1px solid $color-secondary-dark;
		&:hover {
			color: $color-secondary-dark;
		}
		&::before {
			color: $color-secondary-dark;
		}
		&::after {
			border: 1px solid $color-secondary-dark;
		}
		&:hover::before {
			color: $color-white;
		}
		&:hover::after {
			background-color: $color-secondary-dark;
		}
	}
}