// All main typography styles.
//
// 1.          - Hide @extend %debugRWD for remove the RWD debug bar on top of page.
// 2.				- Font-sizing for content - Preserves vertical-rythm (Source: http://soqr.fr/vertical-rhythm/).
// 3.				- Font-Weight for headings
// 4.				- HR base style
//
// Styleguide typography.


html {
	font-family: $font-stack-common;
	line-height: $line-height;
	color: $color-base;
	font-weight:$font-weight-common;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// 1.
body {
	@include rem-fontsize($font-size-base);
	background: $color-white;
}

// 2.
p, ul, ol, dl,
blockquote, pre,
td, th,
legend, label, textarea,
caption, figure {
	line-height: $line-height;
	margin: 0 0 $base-spacing/2 0;
}

// 3.
h1, h2, h3, h4, h5, h6 {
	font-weight: $font-weight-headings;
}

// 4.
hr {
	border: 0;
	font-size: 0;
	height: 1px;
	margin: $base-spacing 0;
	background-color: $color-base;
}

// 5.
::-moz-selection {
  background-color: $color-base;
  color: $color-white;
  text-shadow: none;
}
::selection {
  background-color: $color-base;
  color: $color-white;
  text-shadow: none;
}