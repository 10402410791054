// All styles settings, variables and fonts.
//
// $print-stylesheet             - Enable print stylesheet.
// $line-height 						- Base line-height.
// $font-size-base  					- Base font size: should be define with 16 or should use the Bourbon $em-base var in bourbon/settings/_px-to-em.scss.
// $font-size-*				      - Alternate font-size. Use for generate the alternate font size rules in _typography.scss.
// $h*-font-size       				- Heading font-size: Use for generate the hx, .hx-like rules in _typography.scss.
// @font-face/@import 				- Import here all the custom fonts you need.
// $font-stack-* 						- Some font stack family.
// $font-weight-* 					- Font stack family weight.
// $color-* 							- All main colors. All colors should be decline with $color-primary-lighter or $color-primary-darker for example.
//	$color-flash-* 					- All flash alert colors.
//	$background-color-base 			- Base background color.
//	$base-radius 						- Base radius size.
//	$base-spacing 						- Base spacing size.
//
// No styleguide reference.


$print-stylesheet: false;
$line-height: 1.6;

$font-size-smallest: 10px;
$font-size-smaller: 12px;
$font-size-small: 14px;
$font-size-base: $em-base;
$font-size-big: 18px;
$font-size-bigger: 20px;

$h6-font-size: 20px;
$h5-font-size: 28px;
$h4-font-size: 32px;
$h3-font-size: 36px;
$h2-font-size: 48px;
$h1-font-size: 60px;


// font stacks
@import url(https://fonts.googleapis.com/css?family=Merriweather:300,400,700,900);
@import url(https://fonts.googleapis.com/css?family=Merriweather+Sans:300,400,700,800);


/*
@font-face {
  font-family: 'NationalRegular';
  src: url('../../fonts/National/NationalWeb-Regular.eot');
  src: url('../../fonts/National/NationalWeb-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/National/NationalWeb-Regular.woff2') format('woff2'),
  url('../../fonts/National/NationalWeb-Regular.woff') format('woff'),
  url('../../fonts/National/NationalWeb-Regular.ttf') format('truetype'),
  url('../../fonts/National/NationalWeb-Regular.svg#NationalRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'NationalBold';
  src: url('../../fonts/National/NationalWeb-Semibold.eot');
  src: url('../../fonts/National/NationalWeb-Semibold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/National/NationalWeb-Semibold.woff2') format('woff2'),
  url('../../fonts/National/NationalWeb-Semibold.woff') format('woff'),
  url('../../fonts/National/NationalWeb-Semibold.ttf') format('truetype'),
  url('../../fonts/National/NationalWeb-Semibold.svg#NationalBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TiemposRegular';
  src: url('../../fonts/Tiempos/TiemposTextWeb-Regular.eot');
  src: url('../../fonts/Tiempos/TiemposTextWeb-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Tiempos/TiemposTextWeb-Regular.woff2') format('woff2'),
  url('../../fonts/Tiempos/TiemposTextWeb-Regular.woff') format('woff'),
  url('../../fonts/Tiempos/TiemposTextWeb-Regular.ttf') format('truetype'),
  url('../../fonts/Tiempos/TiemposTextWeb-Regular.svg#TiemposRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TiemposBold';
  src: url('../../fonts/Tiempos/TiemposTextWeb-Semibold.eot');
  src: url('../../fonts/Tiempos/TiemposTextWeb-Semibold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Tiempos/TiemposTextWeb-Semibold.woff2') format('woff2'),
  url('../../fonts/Tiempos/TiemposTextWeb-Semibold.woff') format('woff'),
  url('../../fonts/Tiempos/TiemposTextWeb-Semibold.ttf') format('truetype'),
  url('../../fonts/Tiempos/TiemposTextWeb-Semibold.svg#TiemposBold') format('svg');
  font-weight: normal;
  font-style: normal;
}
*/


$font-stack-common: Merriweather, Georgia, Times, serif; // ou TiemposRegular
$font-stack-headings: Merriweather, Georgia, Times, serif; // ou TiemposBold ou Serif Light
$font-stack-monospace: 'Merriweather Sans', 'Helvetica Neue', Arial, sans-serif; // ou National
$font-stack-universal: FreeSans, Arimo, "Droid Sans", Helvetica, Arial, sans-serif;

$font-weight-common: 300;
$font-weight-headings: 900;
$font-weight-monospace: 400;


/* Couleurs V1 */
$color-white: #ffffff;
$color-base: #191400; // Noir #191400 ou #222222

$color-primary: #00AEEF; // Bleu #1D78AF ou #00AEEF
$color-primary-dark: shade($color-primary, 15%);
$color-primary-darker: #1D78AF;

$color-secondary: #fed000; // Jaune
$color-secondary-dark: shade($color-secondary, 15%);
$color-secondary-darker: shade($color-secondary, 25%);

$color-third: #dddddd; // Gris
$color-third-light: tint($color-third, 70%); // #F5F5F5
$color-third-dark: shade($color-third, 10%);
$color-third-darker: shade($color-third, 30%);
$color-third-darkest: shade($color-third, 60%);

$color-flash-red: #e55c53;
$color-flash-yellow: #FFF6BF;
$color-flash-green: #7eab67;

$background-color-base: $color-white;

$base-radius: 3px;
$base-spacing: 20px;
