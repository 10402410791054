// Form.
//
// .m-form							- Module form styles.
//
// Styleguide for form module.

.m-form {
	//
	&__alert {
		display: none;
	}
	&__input-container {
		position: relative;
		display: block;
		@include padding($base-spacing/2 0 0 0);
		@include clearfix;
		border-bottom: 1px solid $color-third-darkest;
		&--button {
			border-bottom: none;
			@include padding(0);
			text-align: right;
		}
		&__field {
			position: relative;
			display: block;
			float: right;
			width: 100%;
			border: none;
			background-color: transparent;
			color: $color-white;
			@include margin($base-spacing 0 $base-spacing/4 0);
			@include padding($base-spacing/3);
			//@include rem-fontsize($font-size-big);
			@include transition(background-color .3s ease-out);
			&:focus {
				outline: none;
				background-color: $color-third-darker;
			}
		}
		&__textarea {
			position: relative;
			display: block;
			width: 100%;
			height: 150px;
			border: none;
			background-color: $color-third-dark;
			color: $color-white;
			@include padding($base-spacing/3);
			@include transition(background-color .3s ease-out);
			&:hover {
				background-color: $color-third-darker;
			}
			&:focus {
				outline: none;
				background-color: $color-third-darker;
			}
		}
		&__label {
			display: inline-block;
			float: right;
			width: 100%;
			@include padding($base-spacing*1.7 0 0 0);
			margin: 0;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			position: absolute;
			text-align: left;
			pointer-events: none;
			&-content {
				position: relative;
				display: block;
				@include transition(all .3s ease-out);
				@include monospace-text($color-third-dark);
				@include rem-fontsize($font-size-smaller);
			}
			&-textarea {
				float: none;
				position: relative;
				display: block;
				@include monospace-text($color-third-dark);
				@include rem-fontsize($font-size-smaller);
				@include padding($base-spacing*1.4 0 0 0);
				@include margin(0 0 $base-spacing/4 0);
				cursor: default;
			}
		}
		&__button {
			position: relative;
			width: 200px;
			line-height: $line-height;
			border: none;
			overflow: hidden;
			@include padding($base-spacing/2 $base-spacing*2 $base-spacing/2 0);
			@include rem-fontsize($font-size-smaller);
			background: $color-base;
			@include monospace-text($color-white);
			&--absolute {
				float: none;
				position: absolute;
				bottom: 0;
				right: -210px;
				z-index: 10;
				background: $color-secondary;
				@include monospace-text($color-base);
			}
			&::before {
				content: '';
				z-index: 10;
				position: absolute;
				top: 14px;
				right: 18px;
				width: 16px;
				height: 16px;
				background-image: url('../../images/icon-arrow-right.svg');
				background-size: 16px 16px;
				background-repeat: no-repeat;
				background-position: center;
				@include transition(right .3s ease-out);
			}
			&::after {
				content: '';
				position: absolute;
				width: 55%;
				height: 200%;
				z-index: 1;
				right: -30%;
				top: -30%;
				background-color: $color-secondary-darker;
				@include transform(rotate(25deg));
				@include transition(width .3s ease-out);
			}
			&:hover::before {
				right: 12px;
			}
			&:hover::after {
				width: 49%;
			}
		}
	}
	.m-form__input-container__field:focus + .m-form__input-container__label .m-form__input-container__label-content,
	.m-form__input--filled .m-form__input-container__label-content {
		@include transform(translate3d(0, -195%, 0));
	}


}