// Services.
//
// .m-services							- Module services styles.
//
// Styleguide for services module.


.m-services {
	background-image: url('../../images/services-bg.svg');
	background-position: bottom center;
	background-repeat: repeat-x;
	@include padding($base-spacing*3 $base-spacing);
	@include media($small-up) {
		@include padding($base-spacing*4 $base-spacing*2 $base-spacing*4 $base-spacing*2);
	}
	section {
		@include outer-container;
		@include row(table);
	}
	h3 {
		@include headings(h2);
		color: $color-secondary;
	}
	h4 {
		@include monospace-text($color-third-dark);
	}
	h5 {
		margin: 0;
	}
		hr.thin, hr.heavy, hr.heavyFull {
			background-color: $color-third;
		}
	&__content {
		//
		&--left {
			@include fill-parent;
			@include padding(0 0 $base-spacing 0);
			@include row;
			@include media($small-up) {
				@include span-columns(6, table);
				@include padding(0 $base-spacing*2 0 0);
			}
		}
		&--right {
			@include fill-parent;
			@include padding(0);
			@include row;
			@include media($small-up) {
				@include span-columns(6, table);
				@include padding(0 0 0 $base-spacing*2);
			}
		}
		&__more {
			text-align: center;
			@include media($small-up) {
				text-align: left;
			}
		}
	}
	&__icons {
		@include unstyled;
		@include padding($base-spacing 0);
		@include clearfix;
		display: none;
		position: relative;
		@include media($small-up) {
			@include row(table);
		}
		&__icon {
			@include span-columns(4, block-collapse);
			position: relative;
			svg {
				path {
					@include transition(fill .3s ease-in);
				}
				circle {
					@include transition(fill .3s ease-in);
				}
			}
			i {
				display: block;
				opacity: 0;
				transform: translate(0, 100px);
				transition: opacity 0.3s, transform .6s ease-out;
				&.is-visible {
					transform: translate(0, 0);
					opacity: 1;
				}
			}
			&--developpement i {
				text-align: center;
			}
			&--aussi i {
				text-align: right;
			}
			&--current {
				svg {
					path {
						fill: $color-white;
					}
					circle {
						fill: $color-secondary;
					}
				}
			}
			svg {
				height: auto;
				width: 92%;
			}
		}

	}
}