// Soft reset rules.
//
// 1.          - Avoid top margins on first content element.
// 2.				- Avoid margins on nested elements.
// 3. 			- Prevents those to break out of their container.
// 4. 			- Hide script and style tags in case of table mode.
// 5. 			- Viewport fixing for RWD.
// 6. 			- Force word wrapping on small screens only.
//
// No styleguide reference.


html, body {
	margin: 0;
	padding: 0;
	overflow-x: hidden;
}

code, pre, samp, kbd {
	white-space: pre-wrap;
	font-family: $font-stack-monospace;
	line-height: 1em;
}

code, kbd, mark {
	border-radius: $base-radius;
}

em {
	font-style: italic;
}

strong {
	font-weight: bold;
}

kbd {
	padding: 0 2px;
	border: 1px solid #999;
}

code {
	padding: 2px 4px;
	background: rgba(0,0,0,.04);
	color: #b11;
}

mark {
	padding:2px 4px;
	background: #ff0;
}

sup, sub {
	vertical-align: 0;
	position: relative;
}

sup {
	bottom: 1ex;
}

sub {
	top: .5ex;
}

// 1.
p, ul, ol dl,
blockquote, pre,
h1, h2, h3, h4, h5, h6 {
	&:first-child {
		margin-top: 0;
	}
}

// 2.
li {
	ul, ol {
		margin-top: 0;
		margin-bottom: 0;
	}
}

// 3.
img, table, td,
blockquote, code, pre,
textarea, input, video {
	max-width: 100%;
}

img {
	vertical-align: middle;
}

// 4.
body > script,
body > style {
	display: none !important;
}

// 5.
@-webkit-viewport {
	width: device-width;
	zoom: 1.0;
}
@-moz-viewport {
	width: device-width;
	zoom: 1.0;
}
@-ms-viewport {
	width: device-width;
	zoom: 1.0;
}
@-o-viewport {
	width: device-width;
	zoom: 1.0;
}
@viewport {
	width: device-width;
	zoom: 1.0;
}


// 6.
div, textarea,
table, td, th,
code, pre, samp {
	@include hyphens(auto);
	@include media($tiny-up) { @include hyphens(none); }
}