// Helpers for this styles.
//
// All custom project helpers and mixins.
//
// No styleguide reference.


@mixin monospace-text($color: $color-base)  {
	text-transform: uppercase;
	font-family: $font-stack-monospace;
	font-weight: $font-weight-monospace;
	@include rem-fontsize($font-size-small);
	letter-spacing: 2px;
	color: $color;
}


@mixin diagonal-background($color: $color-base, $degre: 1deg, $zindex: 2) {
	position: relative;
	z-index: $zindex;
	&::before {
		content: '';
		position: absolute;
		pointer-events: none;
		top: 0;
		left: -25%;
		width: 150%;
		height: 25%;
		z-index: -1;
		background-color: $color;
		@include transform(rotate($degre));
		@include transform-origin(0 0);
	}
}