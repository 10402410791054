/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: $color-secondary;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 10px;
  @include prefixer(box-shadow, 0px 5px 15px 0px rgba(0, 0, 0, 0.10), webkit moz spec);



}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  opacity: 1.0;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
      -ms-transform: rotate(3deg) translate(0px, -4px);
          transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  left: 50%;
  top: 50vh;
  transform: translate(-50%, -50%);

  &:before {
    display: block;
    position: fixed;
    content: '';
    width: 45px;
    height: 45px;
    left: 50%;
    top: 100px;
    transform: translate(-50%, -50%);
    background-image: url('../../images/logo-hellodeloo__grisclair.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    z-index: 100;
    opacity: 1;
  }


}

#nprogress .spinner-icon {
  width: 20px;
  height: 20px;
  box-sizing: border-box;

  border: solid 20px transparent;
  border-top-color: $color-third-dark;
  border-left-color: $color-third;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
          animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0%   { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes nprogress-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

