// notes.
//
// .m-notes							- Module notes styles.
//
// Styleguide for notes module.

.m-notes {
	background-color: $color-primary;
	color: $color-white;
	@include padding($base-spacing*3 $base-spacing);
	@include media($small-up) {
		@include padding($base-spacing*4 $base-spacing*2 $base-spacing*4 $base-spacing*2);
	}
	section {
		@include outer-container;
		@include row(table);
	}
	h3 {
		@include headings(h2);
	}
	h4 {
		@include monospace-text($color-primary-dark);
	}
	hr.thin, hr.heavy, hr.heavyFull  {
		background-color: $color-primary-dark;
		margin-left: auto;
		margin-right: auto;
	}
	&__content {
		&--full {
			@include fill-parent;
			@include row;
			text-align: center;
			.button--primary-dark {
				&:hover {
					background-color: transparent;
					border: 1px solid $color-white;
					color: $color-white;
				}
				&::before {
					color: $color-primary-dark;
				}
				&::after {
					border: 1px solid $color-primary-dark;
				}
				&:hover::before {
					color: $color-primary-dark;
				}
				&:hover::after {
					background-color: $color-white;
					color: $color-primary-dark;
				}
			}
		}
		&--left {
			@include fill-parent;
			@include padding(0 0 $base-spacing 0);
			@include row;
			@include media($small-up) {
				@include span-columns(6, table);
				@include padding($base-spacing/2 $base-spacing*2 $base-spacing/2 0);
				border-right: 1px solid $color-primary-dark;
			}
			a {
				color: $color-white;
				&:hover, &:focus {
					color: $color-white;
				}
			}
		}
		&--right {
			@include fill-parent;
			@include padding(0);
			@include row;
			@include media($small-up) {
				@include span-columns(6, table);
				@include padding($base-spacing/2 0 $base-spacing/2 $base-spacing*2);
			}
			vertical-align: middle;
		}
		&__items {
			ul {
				@include unstyled;
			}
			li {
				@include monospace-text($color-white);
				@include rem-fontsize($font-size-smaller);
				border-top: 1px solid $color-primary-dark;
				color: $color-third;
				&:first-child {
					border-top: none;
				}
				a {
					display: block;
					@include padding($base-spacing $base-spacing/2 $base-spacing $base-spacing*1.5);
					background-image: url('../../images/icon-arrow-right__white.svg');
					background-size: 16px 16px;
					background-repeat: no-repeat;
					background-position: left center;
					color: $color-white;
					border-bottom: none;
					@include transition(all .3s ease-out);
					&:hover, &:focus, &:active {
						text-decoration: none;
						color: $color-white;
						background-color: $color-primary-dark;
						background-position: $base-spacing/1.5 center;
						@include padding($base-spacing $base-spacing/2 $base-spacing $base-spacing*2);
					}
				}
			}
		}
	}
	&__title {
		@include monospace-text($color-white);
		@include rem-fontsize($font-size-small);
		margin: 0;
		a {
			display: block;
			&:hover { text-decoration: none; }
		}
	}
	&__text {
		position: relative;
		display: block;
		border: 10px solid $color-white;
		border-bottom: 1px solid $color-white;
		@include margin($base-spacing/1.5 0 0 0);
		@include padding($base-spacing/2);
		@include transition(background-color .3s ease-out);
		&::before {
			content: '';
			display: block;
			position: absolute;
			height: 30%;
			width: 10px;
			top: 60%;
			left: -10px;
			background-color: $color-primary;
			z-index: 1;
			@include transition(background-color .3s ease-out);
		}
		&::after {
			content: '';
			display: block;
			position: absolute;
			height: 70%;
			width: 10px;
			top: 30%;
			right: -10px;
			background-color: $color-primary;
			z-index: 1;
			@include transition(background-color .3s ease-out);
		}
		&:hover, &:focus {
			background-color: $color-primary-dark;
			&::before, &::after { background-color: $color-primary-dark; }
		}
		a {
			display: block;
			&:hover { text-decoration: none; }
		}
	}
	&__time {
		@include margin(0);
		time {
			@include padding($base-spacing/4 $base-spacing/2);
			background-color: $color-white;
			color: $color-primary;
			@include rem-fontsize($font-size-small);
			font-family: $font-stack-common;
			font-weight: 700;
		}
	}

}