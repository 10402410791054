// Browsers fix.
//
// 1.				- IE white-space fix.
// 2.          - Pictures fix for IE8, for Gmap3 and remove blue border on IE.
// 3.          - Bugfix alignment.
// 4.          - Removes default vertical scrollbar on empty textarea in IE6/7/8/9 and prevents horizontal resizing.
// 5.          - Clickable input types in iOS.
// 6.          - Corrects excess space around these inputs in IE8/9.
// 7.          - If select styling bugs on WebKit.
// 8.				- Removes inner padding and border in FF3+.
//
// No styleguide reference.


// 1.
code, pre, samp, kbd {
	white-space: pre-line;
}

// 2.
img {
	width: auto;
	:not(.gm-style),
	:not(.gmnoscreen),
	:not(.gmnoprint) {
		& {
			height: auto !important;
		}
	}
	.gm-style,
	.gmnoscreen,
	.gmnoprint {
		& {
			max-width: none !important;
		}
	}
	a & {
		border: 0;
	}
}

// 3.
input,
button, select,
label, .btn {
	vertical-align: middle;
}

// 4.
textarea {
	overflow: auto;
	resize: vertical;
}

// 5.
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
}

// 6.
input[type="checkbox"],
input[type="radio"] {
	padding: 0; //*1
}

// 7.
// select { -webkit-appearance: none; }

// 8.
button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
}