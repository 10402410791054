// Realisation layout.
//
// .realisation							- Main projets styles.
//
// Styleguide realisation layout parts.


.l-realisations-single {
  .m-header__nav__nav ul li.menu-item-11 a {
    color: $color-base;
    &::after {
      height: 10px;
      background-color: $color-base;
    }
  }
  .m-header__hello {
    background-image: url('../../images/realisations-hello-bg.jpg');
    background-position: top center;
  }

  .l-page__container__content--left {
    vertical-align: top;
    @include fill-parent;
    @include padding(0);
    @include row;
    @include media($small-up) {
      @include span-columns(6, table);
    }
  }

  .m-realisations__content__figure {
    a {
      &:after {
        background-image: url('../../images/realisations-device__blanc.png');
        border-bottom: 1px solid $color-white;
      }
      img {
        filter: grayscale(33%);
        opacity: 1;
      }
    }
  }
  .m-realisations__content__more {
    text-align: center;
    @include media($small-up) {
      text-align: center;
    }
  }

}