// All main links styles.
//
// a 												- Base links style.
//
// Styleguide links.


a {
	color: $color-base;
	text-decoration: none;
	font-weight: 400;
	&:hover, &:focus, &:active {
		text-decoration: underline;
	}
	&.primary {
		color: $color-primary;
	}
}

