@charset "UTF-8";

// Default Variables

$slick-font-path: "../../fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "../../images" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "←" !default;
$slick-next-character: "→" !default;
$slick-dot-character: "•" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
	@if function-exists(image-url) {
		@return image-url($url);
	}
	@else {
		@return url($slick-loader-path + $url);
	}
}

@function slick-font-url($url) {
	@if function-exists(font-url) {
		@return font-url($url);
	}
	@else {
		@return url($slick-font-path + $url);
	}
}

/* Slider */

.slick-list {
	.slick-loading & {
		background: #fff slick-image-url('../../images/ajax-loader.gif') center center no-repeat;
	}
}

.slick-track {
	position: relative;
	top: 0;
	left: 0;
	display: block;
}

/* Arrows */

.slick-prev,
.slick-next {
	position: absolute;
	display: block;
	height: 32px;
	width: 26px;
	line-height: 0px;
	font-size: 0px;
	cursor: pointer;
	color: transparent;
	bottom: 43%;
	padding: 10px;
	border: none;
	outline: none;
	background-repeat: no-repeat;
	background-position: center center;
	background-color: transparent;
	z-index: 100;
	&.slick-disabled {
		opacity: $slick-opacity-not-active;
	}
}

/* Dots */

.slick-slider {
	overflow: hidden;
	margin: 30px 0 10px 0;
}

.slick-slide {
	display: inline-block;
	position: relative;
	text-align: center;
	&:hover {
		background-color: $color-white;
	}
}

.slick-dots {
	position: absolute;
	bottom: -$base-spacing*2;
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	width: 100%;
	margin: 0;
	li {
		position: relative;
		display: inline-block;
		height: 14px;
		width: 14px;
		margin: 0 5px;
		padding: 0;
		cursor: pointer;
		button {
			border-radius: 50%;
			outline: none;
			border: 0;
			background-color: $color-third;
			border: 1px solid $color-third;
			display: block;
			height: 14px;
			width: 10px;
			outline: none;
			line-height: 0px;
			font-size: 0px;
			color: transparent;
			cursor: pointer;
			&:hover, &:focus {
				outline: none;

			}
		}
		&.slick-active button {
			background-color: $color-white;
		}
	}
}