// notes layout.
//
// .notes							- Main notes styles.
//
// Styleguide notes layout parts.


.l-notes {
	//
	.m-header__hello {
		background-image: url('../../images/notes-hello-bg.jpg');
		background-position: top center;
	}
	.l-page__container {
		h3 {
			color: $color-primary;
			@include margin(0 0 $base-spacing 0);
			a {
				color: $color-primary;
				text-decoration: none;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
	.l-notes__thumbnail {
		@include padding(0 0 $base-spacing/2 0);
		@include margin(0);
	}
	.l-notes__time {
		@include margin(0 0 $base-spacing*1.5 0);
		time {
			@include padding($base-spacing/4 $base-spacing/2);
			background-color: $color-primary;
			color: $color-white;
			@include rem-fontsize($font-size-small);
			font-family: $font-stack-common;
			font-weight: 700;
		}
		&--small {
			@include margin(0);
			time {
				@include padding($base-spacing/4 $base-spacing/2);
				background-color: $color-third;
				color: $color-white;
				@include rem-fontsize($font-size-small);
				font-family: $font-stack-common;
				font-weight: 700;
			}
		}
		&--tiny {
			@include margin(0);
			time {
				color: $color-third;
				@include rem-fontsize($font-size-small);
				font-family: $font-stack-common;
				font-weight: 700;
			}
		}
	}
	.l-page__container__content--left {
		border: none;
		@include padding(0 0 $base-spacing 0);
		@include media($small-up) {
			border-right: 1px solid  $color-third;
			@include padding(0 $base-spacing*2 0 0);
		}
	}
	.l-page__container__content--right .l-notes__item:nth-child(1)	{
		@include padding(0 0 $base-spacing*2 0);
		border-bottom: 1px solid $color-third;
	}
	.l-page__container__content--right .l-notes__item:nth-child(2)	{
		@include padding($base-spacing*2 0 0 0);
	}
	.l-notes__item {
		//
		&__title {
				@include headings(h5);
				color: $color-primary;
				text-transform: inherit;
				@include margin(0 0 $base-spacing 0);
				a {
					color: $color-primary;
					&:hover {
						text-decoration: none;
					}
				}
			&--small {
				@include margin(0);
				@include monospace-text($color-primary);
				color: $color-primary;
				@include rem-fontsize($font-size-smaller);
				text-transform: inherit;
				a {
					@include monospace-text($color-primary);
					color: $color-primary;
					@include rem-fontsize($font-size-smaller);
					&:hover {
						text-decoration: none;
					}
				}
			}
		}
		&__excerpt {
			@include margin(0 0 $base-spacing 0);
			a {
				color: $color-base;
				display: block;
				text-decoration: none;
			}
		}
	}
	hr.separatorFull { background-color: $color-third; }
}
