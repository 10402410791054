// All tables styles.
//
// 1.				- Prevent reCAPTCHA from exploding
// 2.          - Remove thead on tiny screens.
//
// Styleguide tables.

table,
.table {
	max-width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
	vertical-align: top;
}

// 1.
table {
	width: 100%;
	border: none;
	margin-bottom: $base-spacing/2;
	&#recaptcha_table,
	&.table-auto {
		table-layout: auto;
	}
}

.table {
	display: table;
}

caption {
	padding: $base-spacing/2;
	color: tint($color-base, 10%);
	font-style: italic;
}

// 2.
thead {
	@include media(tiny-only) {
		display: none;
	}
}

tr > * + * {
	border-left: none;
}

th,
td {
	padding: $base-spacing/10 $base-spacing/5 $base-spacing/10 0;
	text-align: left;
	border-bottom: none;
}