// 404 layout.
//
// .404							- Main 404 styles.
//
// Styleguide 404 layout parts.


.l-404 {
	//
	.l-page__container .l-page__container__content--full {
		height: $base-spacing*8;
		@include margin(0 0 $base-spacing*2 0);
		background-size: auto 200%;
		background-repeat: no-repeat;
		background-position: top left;
		background-image: url('../../images/icon-404.svg');
		@include prefixer(animation, animated404 3s linear infinite, webkit moz ms o spec);
	}
}

@include keyframes(animated404) {
	0% { background-position: top left; }
	49.99% { background-position: top right; }
	50% { background-position: bottom right; }
	99.99% { background-position: bottom left; }
	100% { background-position: top left; }
}