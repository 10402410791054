// Helpers.
//
// %debugRWD:after																- Debug bar for RWD for <body> on top of page..
// antialiased          														- Antialiased text.
// unstyled     																	- Unstyled list or item.
// %flash-success, %flash-error, %flash-notice, %flash-alert     	- Flash alert.
//
// Styleguide helpers.

// Mixin to handle REM font sizing with PX fallback
//
// $value				- Value in PX without unit
// $base					- If you nedd to change the $font-size-base, the font-size of <html>
//
// Compatibility untested.
@mixin rem-fontsize($value, $base: $font-size-base) {
	$value: strip-units($value);
	$base: strip-units($base);
	font-size: $value * 1px;
 	font-size: $value / $base * 1rem;
}

%debugRWD:after {
	display: block;
	position: fixed;
	@include rem-fontsize(10);
	text-align: center;
	bottom: 0;
	left: 0;
	z-index: 99999;
	@include size(100% 20px);
	content: 'all';
	color: white;
	background-color: black;
	@include media($tiny-up) {
		content: 'tiny-up';
		background-color: rgba(black, 0.8);
	}
	@include media($small-up) {
		content: 'small-up';
		background-color: rgba(black, 0.7);
	}
	@include media($medium-up) {
		content: 'medium-up';
		background-color: rgba(black, 0.5);
	}
	@include media($large-up) {
		content: 'large-up';
		color: black;
		background-color: rgba(black, 0.3);
	}
	@include media($xlarge-up) {
		content: 'large-up';
		color: black;
		background-color: rgba(black, 0.1);
	}
}

// Omega reset for Neat Omega mixin.
//
// $nth 				- Number of the nth-child to reset.
//
// Compatibility untested.
@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: flex-gutter(); }
  &:nth-child(#{$nth}+1) { clear: none; }
}

@mixin antialiased {
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin unstyled {
	list-style: none;
	margin: 0;
	padding: 0;
}

// Responsive hiding and showing. {breakpoint}-hidden and {breakpoint}-visible helpers.
//
// $breakpoints 				- List of all breakpoints.
// %#{$size}-hidden   		- Generated hidden style on output.
// %#{$size}-visible   		- Generated visible style on output.
//
// Compatibility untested.
$breakpoints:
tiny-up $tiny-up,
small-up $small-up,
medium-up $medium-up,
large-up $large-up;

@each $size, $size-up in $breakpoints {
	%#{$size}-hidden, .#{$size}-hidden {
		@include media($size-up) {
			visibility: hidden !important;
			display: none !important;
		}
	}
	%#{$size}-visible, .#{$size}-visible {
		@include media($size-up) {
			visibility: visible !important;
			display: block !important;
		}
	}
}


// Headings
//
// $size 					- The size of heading. Could be: h1, h2, ...
//
// Compatibility untested.
@mixin headings($size) {
	margin: 0 0 $base-spacing/2 0;
	font-family: $font-stack-headings;
	font-weight: $font-weight-headings;
	@include antialiased;
	@if $size == 'h1' {
		@include rem-fontsize($h2-font-size);
		line-height: 1.1;
	} @else if $size == 'h2' {
		@include rem-fontsize($h3-font-size);
		line-height: 1.1;
	} @else if $size == 'h3' {
		@include rem-fontsize($h4-font-size);
		line-height: 1.2;
	} @else if $size == 'h4' {
		@include rem-fontsize($h5-font-size);
		line-height: 1.2;
	} @else if $size == 'h5' {
		@include rem-fontsize($h5-font-size);
		line-height: 1.3;
	} @else if $size == 'h6' {
		@include rem-fontsize($h6-font-size);
		line-height: 1.3;
	}
	@include media($medium-up) {
		@if $size == 'h1' {
			@include rem-fontsize($h1-font-size);
			line-height: 1.1;
		} @else if $size == 'h2' {
			@include rem-fontsize($h2-font-size);
			line-height: 1.1;
		} @else if $size == 'h3' {
			@include rem-fontsize($h3-font-size);
			line-height: 1.2;
		} @else if $size == 'h4' {
			@include rem-fontsize($h4-font-size);
			line-height: 1.2;
		} @else if $size == 'h5' {
			@include rem-fontsize($h5-font-size);
			line-height: 1.3;
		} @else if $size == 'h6' {
			@include rem-fontsize($h6-font-size);
			line-height: 1.3;
		}
	}
}




// Flash alert
//
// $type 					- The type of flash. Could be: alert, error, success.
//
// Compatibility untested.
@mixin flash($type) {
	display: block;
	font-weight: bold;
	margin-bottom: $base-spacing/2;
	padding: $base-spacing/2;
	@if $type == 'alert' {
		$color: $color-flash-yellow;
		background: $color;
		color: darken($color, 60);
		a {
			color: darken($color, 70);
			&:hover {
				color: darken($color, 90);
			}
		}
	} @else if $type == 'error' {
		$color: $color-flash-red;
		background: $color;
		color: darken($color, 60);
		a {
			color: darken($color, 70);
			&:hover {
				color: darken($color, 90);
			}
		}
	} @else if $type == 'success' {
		$color: $color-flash-green;
		background: $color;
		color: darken($color, 60);
		a {
			color: darken($color, 70);
			&:hover {
				color: darken($color, 90);
			}
		}
	} @else {
		$color: lighten($color-primary, 40);
		background: $color;
		color: darken($color, 60);
		a {
			color: darken($color, 70);
			&:hover {
				color: darken($color, 90);
			}
		}
	}
}