// Common layout parts.
//
// .main							- Common <main> styles.
//
// Styleguide Common layout parts.


.browseHappy {
	text-align: center;
	@include flash(error);
	@include padding($base-spacing/2);
	@include row;
	position: fixed;
	width: 100%;
	z-index: 9999;
}

body {
 //
}

#pjax-container {
	opacity: 1;
	@include transition(opacity .3s ease-out);
	.is-loading & {
		opacity: 0;
	}
}


hr {
	display: block;
	text-align: left;
	@include margin($base-spacing 0);
	@include transition(width 0.3s ease-in-out);
	&.thin {
		width: 80px;
	}
	&.heavy {
		width: 0px;
		height: 10px;
	}
	&.heavyFull {
		height: 10px;
		width: 80px;
	}
	&.separatorFull {
		max-width: 1200px;
		@include margin($base-spacing*2 auto);
	}
	&.logo {
		max-width: 1200px;
		@include margin($base-spacing*3 auto $base-spacing auto);
		text-align: center;
		&::after {
			content: '';
			display: inline-block;
			position: relative;
			@include size(40px 40px);
			top: -20px;
			background-image: url('../../images/logo-hellodeloo__gris.svg');
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center center;
			background-color: $color-base;
			border: 5px solid $color-base;
		}
	}
	&.arrowBottom {
		@include margin($base-spacing*2 0 $base-spacing 0);
		background-color: transparent;
		text-align: left;
		&::after {
			content: '';
			display: inline-block;
			position: relative;
			@include size(16px 16px);
			left: -8px;
			background-image: url('../../images/icon-arrow-bottom.svg');
			background-repeat: no-repeat;
			background-size: 16px 16px;
		}
	}
	&.hidden {
		background-color: transparent;
		@include margin($base-spacing/1.5 0);
		@include media($small-up) {
			@include margin($base-spacing 0);
		}
	}
}

h5 {
	@include rem-fontsize($font-size-small);
	text-transform: uppercase;
	font-weight: 700;
	display: inline-block;
	@include margin(0 0 $base-spacing/1.5 0);
}

.columnBreakBefore {
	break-before: always;
}


svg {
	fill: currentColor;
}



