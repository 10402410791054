// Footer.
//
// .m-footer							- Module footer styles.
//
// Styleguide for footer module.


.m-footer {
	//
	&__contact {
		background-color: $color-secondary;
		@include padding($base-spacing*3 $base-spacing);
		@include media($small-up) {
			@include padding($base-spacing*3 $base-spacing*2);
		}
		section {
			@include outer-container;
			@include row(table);
		}
		&__detail {
			@include fill-parent;
			@include padding(0 0 $base-spacing 0);
			vertical-align: top;
			@include media($small-up) {
				@include span-columns(6, table);
				@include padding(0 $base-spacing*2 0 0);
			}
		}
		&__form {
			@include fill-parent;
			vertical-align: top;
			@include padding(0);
			@include media($small-up) {
				@include span-columns(6, table);
				@include padding(0 0 0 $base-spacing*2);
			}
		}
		&__email {
			display: inline-block;
			padding-left: $base-spacing*1.2;
			background-image: url('../../images/icon-email.svg');
			background-repeat: no-repeat;
			background-position: left center;
			background-size: 16px;
			@include rem-fontsize(14);
			font-weight: 700;
		}
		&__phone {
			display: inline-block;
			padding-left: $base-spacing*1.2;
			background-image: url('../../images/icon-gsm.svg');
			background-repeat: no-repeat;
			background-position: left center;
			background-size: 16px;
			@include rem-fontsize(14);
			font-weight: 700;
		}
		&__social {
			@include unstyled;
			text-align: left;
			li {
				display: inline-block;
				color: $color-white;
				@include padding(0 $base-spacing/1.5 0 0);
				svg {
					@include size(30px);
					path {
						fill: $color-secondary-dark;
						@include transition(fill .3s ease-out);
					}
					&:hover path { fill: $color-white; }
				}
			}
		}
		h3 {
			@include headings(h2);
		}
		strong {
			display: block;
			@include monospace-text($color-base);
			color: $color-white;
		}
		hr.thin, hr.heavy, hr.heavyFull  {
			background-color: $color-secondary-dark;
		}
		a {
			text-decoration: none;
		}
		.m-form__input-container {
			border-bottom: 1px solid $color-secondary-dark;
		}
		.m-form__input-container--button {
			border-bottom: none;
		}
		.m-form__input-container__label-content, .m-form__input-container__label-textarea {
			color: $color-secondary-darker;
		}
		.m-form__input-container__field:focus {
			background-color: $color-secondary-dark;
		}
		.m-form__input-container__textarea {
			background-color: $color-secondary-dark;
			&:focus, &:hover {
				background-color: $color-secondary-darker;
			}
		}
	}

	&__nav {
		background-color: $color-base;
		color: $color-white;
		@include padding($base-spacing*3 $base-spacing);
		@include media($small-up) {
			@include padding($base-spacing*3 $base-spacing*2 $base-spacing/4 $base-spacing*2);
		}
		&--tiny {
			@include padding($base-spacing $base-spacing);
			@include media($small-up) {
				@include padding($base-spacing $base-spacing*2 $base-spacing/4 $base-spacing*2);
			}
			hr.logo {
				@include margin($base-spacing auto);
			}
		}
		::-moz-selection {
		  background-color: $color-white !important;
		  color: $color-base;
		  text-shadow: none;
		}
		::selection {
		  background-color: $color-white !important;
		  color: $color-base;
		  text-shadow: none;
		}
		section {
			@include outer-container;
			@include row(table);
		}
		hr.logo {
			background-color: $color-third-darkest;
		}
		.m-form__input-container {
			@include padding(0);
			width: calc( 100% - 200px - (#{$base-spacing}/2)  );
		}
		&__navs {
			@include fill-parent;
			@include padding(0 0 $base-spacing 0);
			@include row(table);
			@include media($medium-up) {
				@include span-columns(6, table);
				@include padding(0);
			}
		}
		&__main, &__extra {
			@include fill-parent;
			text-align: center;
			@include media($tiny-up) {
				@include span-columns(6, table);
				text-align: left;
			}
			ul {
				@include unstyled;
				a {
					@include monospace-text($color-base);
					@include rem-fontsize($font-size-smaller);
					color: $color-white;
					text-decoration: none;
				}
			}
			&__greaaat {
				@include padding($base-spacing 0 0 0);
				svg {
					height: 30px;
					.stroke {
						stroke: $color-white;
						@include transition(stroke .3s ease-out);
					}
					&:hover {
						.stroke { stroke: #E7384A; }
					}
				}
				a { border-bottom: none !important; }
			}
		}
		&__main {
			@include padding(0);
			@include media($tiny-up) {
				@include padding(0 $base-spacing 0 0);
			}
		}
		&__extra {
			@include padding(0);
			@include media($tiny-up) {
				@include padding(0 0 0 $base-spacing*2);
			}
		}
		&__newsletter {
			display: none;
			@include media($tiny-up) {
				@include fill-parent;
				@include padding(0);
				@include row;
			}
			@include media($medium-up) {
				@include span-columns(6, table);
				@include padding(0 0 0 $base-spacing*2);
			}
			legend {
				@include monospace-text($color-base);
				@include rem-fontsize($font-size-smaller);
				color: $color-white;
				@include margin(0 0 $base-spacing/4 0);
			}
			#mc-message .alert {
				background-color: $color-flash-red;
			}
		}
		&__copyleft {
			text-transform: uppercase;
			color: $color-third-darker;
			@include monospace-text($color-third-darker);
			@include rem-fontsize($font-size-smallest);
			@include row(table);
			span {
				@include span-columns(6, table);
				&:last-child { text-align: right; }
			}
		}
	}

}