// clients.
//
// .m-clients							- Module clients styles.
//
// Styleguide for clients module.


.m-clients {
	text-align: center;
	background-color: $color-third-light;
	@include padding($base-spacing*3 $base-spacing);
	@include media($small-up) {
		@include padding($base-spacing*4 $base-spacing*2 $base-spacing*6 $base-spacing*2);
	}
	section {
		@include outer-container;
		@include row(table);
	}
	h3 {
		@include headings(h2);
		color: $color-third;
	}
	h4 {
		@include monospace-text($color-third-dark);
	}
	hr.thin, hr.heavy, hr.heavyFull  {
		background-color: $color-third;
		margin-left: auto;
		margin-right: auto;
	}
	&__content {
		position: relative;
		@include fill-parent;
		@include row;
	}
}